import { connect } from 'react-redux'
import * as RegoReducers from '../Business/Reducers/RegoReducers'
import * as StoreSales from '../Components/Store/Sales'
import { WeeklySalesFormState } from '../Business/StateModels'
import * as StoreReducer from '../Business/Reducers/StoreReducer'
import * as Actions from '../Business/Reducers/Actions'
import * as StoreMiddleware from '../Business/Middleware/StoreManager'
import * as Models from '../Business/Models'


export interface StoreSalesDispatchProps {
    selectWeek: (val: Models.IWeeklySalesInfo) => void;
    loadWeekList: (storeid: string) => void;
    submitWeeklySales: (data: WeeklySalesFormState) => void;    
}

export type StoreSalesProps = StoreSales.StoreSalesStateProps & StoreSalesDispatchProps;

export function mapStateToProps(state: any, componentprops: any): StoreSales.StoreSalesStateProps {
    return {
        selectedStore: state.storesales.activeStore,
        weeklist: state.storesales.weeklist,
        selectedWeek: state.storesales.selectedWeek,
        weeklySalesList: state.storesales.storeWeeklySales,
        salesloading: state.ui.saleslistFetching,
        roles: state.storesales.roles,
        classes: componentprops.classes
    };
};

export function mapDispatchToProps(dispatch: any): StoreSalesDispatchProps {
    return {
        selectWeek: (val) => {
            if (val != null) {
                let action: Actions.rpAction = Actions.weekendSelected(val);
                dispatch(action);
            }
        },
        loadWeekList: (storeid) => {
            dispatch(() => { StoreMiddleware.FetchWeekEndOptions(storeid, dispatch); });
        },
        submitWeeklySales: (data: WeeklySalesFormState) => {
            let weeklydata: Models.WeeklySalesJournal = new Models.WeeklySalesJournal();
            weeklydata.Hydrate(data);
            
            dispatch(() => { StoreMiddleware.SaveWeeklySalesLog(weeklydata, dispatch); });
        }
    };
}

export const MappedStoreSalesPage = connect<StoreSales.StoreSalesStateProps, StoreSalesDispatchProps, any>(mapStateToProps, mapDispatchToProps)(StoreSales.default)