import * as redux from 'redux'
import * as actions from './Actions'


export interface UIState {
    isFetching: boolean;
    storelistFetching: boolean;
    saleslistFetching: boolean;
}

export function processState(state: UIState, action: actions.rpAction): UIState {
    let outstate = state || { isFetching: false, storelistFetching: false, saleslistFetching: false };
    switch (action.type) {
        case actions.RegoAction.SELECTSTORE:
            return Object.assign({}, outstate, { saleslistFetching: true });
        case actions.RegoAction.WEEKLYSALESLISTLOADED:
            return Object.assign({}, outstate, { saleslistFetching: false });
        default:
            break;
    }

    return outstate;
}
