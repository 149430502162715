import React, { useEffect, useState, useReducer } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Tooltip, FormControl, FormGroup, InputLabel, FormHelperText, Select, MenuItem, OutlinedInput, FormControlLabel, DialogActions, Button, makeStyles, Theme, createStyles, Checkbox } from '@material-ui/core';
import { IUserInfo, FormError, IFranchiseSummary, IStoreData, UserRole } from '../../Business/Models';
import { SelectFranchise } from './SelectFranchises';
import { SelectStores } from './SelectStores';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

export interface IUserEditProps {
    open: boolean;
    user: IUserInfo;
    submitResult: (u: IUserInfo) => void;
    cancelForm: () => void;
    franchises: Array<IFranchiseSummary>;
    stores: Array<IStoreData>;
}

enum AccessLevel {
    Administrator = "Administrator",
    Franchisee = "Franchisee"
}

interface IUserEditState {
    errors: { [id: string]: FormError };
    accessLevel: null | AccessLevel;
    franchises: Array<string>;
    stores: Array<string>;
    resetpassword: boolean;
    deactivateuser: boolean;
    confirmdeactivate: string;
    activateuser: boolean;
    changed: boolean;
}

const GetFormState: (user: IUserInfo) => IUserEditState = (user) => {
    return {
        errors: {},
        accessLevel: user.roles.find(r => r.name == "Administrator") != null ? AccessLevel.Administrator : AccessLevel.Franchisee,
        franchises: user.roles.filter(r => r.contexttype == 'Franchise').map(r => r.contextid),
        stores: user.roles.filter(r => r.contexttype == 'Store').map(r => r.contextid),
        resetpassword: false,
        deactivateuser: false,
        confirmdeactivate: "",
        activateuser: false,
        changed: false
    }
}

interface IFormOptions {
}

export const UserEditForm: React.FC<IUserEditProps> = (props, context) => {
    const classes = useStyles();
    const [formState, setFormState] = useState<IUserEditState>(GetFormState(props.user));
    const [options, setFormOptions] = useState<IFormOptions>({
    });

    const handleSubmit: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void = (e) => {
        let storeroles: Array<UserRole | undefined> = formState.stores.map<UserRole | undefined>((s, i) => {
            let store: IStoreData | undefined = props.stores.find(st => st.id == s);
            if (store != null) {
                return {
                    contextid: store.id,
                    contextname: store.name,
                    contexttype: "Store",
                    id: "",
                    name: formState.accessLevel || AccessLevel.Franchisee
                };
            }
        });

        let franchiseroles: Array<UserRole | undefined> = formState.franchises.map<UserRole | undefined>((f, i) => {
            let franchise: IFranchiseSummary | undefined = props.franchises.find(fr => fr.id == f);
            if (franchise != null && formState.accessLevel == AccessLevel.Administrator) {
                return {
                    contextid: franchise.id,
                    contextname: franchise.name,
                    contexttype: "Franchise",
                    id: "",
                    name: AccessLevel.Administrator
                };
            }
        });

        let userroles: Array<UserRole> = [];
        franchiseroles.forEach((fr) => {
            if (fr != null) {
                userroles.push(fr);
            }
        });
        storeroles.forEach((sr) => {
            if (sr != null) {
                userroles.push(sr);
            }
        });


        let saveuser: IUserInfo = {
            userid: props.user.userid,
            username: props.user.username,
            isactive: props.user.isactive,
            roles: userroles
        };

        props.submitResult(saveuser);
    }

    const handleCancel: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void = (e) => {
        props.cancelForm();
    }

    const selectAccess: (event: React.ChangeEvent<{ name?: string | undefined, value: any }>, child: React.ReactNode) => void = (e, c) => {
        if (e != null && e.target != null) {
            setFormState({ ...formState, accessLevel: e.target.value });
        }
    }

    const selectFranchise: (franchiseId: string) => void = (fid) => {
        let ix: number | undefined = formState.franchises.indexOf(fid);
        let newfsel: Array<string> = [];
        let cursel: Array<string> = formState.franchises;
        if (ix == -1) {
            newfsel = [...cursel, fid];
        }
        else {
            cursel.splice(ix, 1);
            newfsel = cursel;
        }
        setFormState({ ...formState, franchises: newfsel });
    }

    const toggleStore: (storeid: string) => void = (storeid) => {
        let ix: number | undefined = formState.stores.indexOf(storeid);
        let newstoresel: Array<string> = [];
        let cursel: Array<string> = formState.stores;
        if (ix == -1) {
            newstoresel = [...cursel, storeid];
        }
        else {
            cursel.splice(ix, 1);
            newstoresel = cursel;
        }
        setFormState({ ...formState, stores: newstoresel });
    }

    /*
     * Access Level (Franchisee/Admin) 
     * Franchises
     * Stores (text search?)
     * Reset Password
     * Deactivate User
    */
    return (
        <Dialog open={props.open} maxWidth="xs">
            <DialogTitle>Edit User: {props.user.username}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Modify user's roles and request changes below.
                </DialogContentText>
                <form>                    
                    <Tooltip title="Select user privilege level" placement="right" classes={{ tooltip: classes.tooltipstyle }}>
                        <FormControl fullWidth margin='dense' variant='outlined'>
                            <InputLabel id="access-label">Access Level</InputLabel>
                            <Select
                                id="access-select"
                                value={formState.accessLevel != null ? formState.accessLevel : ""}
                                onChange={selectAccess}
                                labelWidth={100}>
                                <MenuItem value={AccessLevel.Franchisee}>Franchisee</MenuItem>
                                <MenuItem value={AccessLevel.Administrator}>Administrator</MenuItem>
                            </Select>
                        </FormControl>
                    </Tooltip>
                    {formState.accessLevel == AccessLevel.Administrator ? (
                        <SelectFranchise franchises={props.franchises} selectedFranchises={formState.franchises} selectFranchise={selectFranchise}  />
                    ) : null}
                    <SelectStores stores={props.stores} selectedStores={formState.stores} toggleStore={toggleStore} />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} classes={{ root: classes.savebutton }}>
                    <SaveIcon />
                </Button>
                <Button onClick={handleCancel} classes={{root: classes.cancelbutton}}>
                    <CancelIcon />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    tooltipstyle: {
        fontSize: 'medium',
        backgroundColor: 'black'
    },
    warningstyle: {
        color: 'red',
        fontWeight: 'bold'
    },
    cancelbutton: {
        backgroundColor: 'darkred',
        '&:hover': {
            backgroundColor: 'red',
            color: 'black'
        },
        color: 'white'
    },
    savebutton: {
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'lightgreen',
            color: 'black'
        },
        color: 'white'
    }
}));