import * as redux from 'redux'
import * as actions from './Actions'
import { GUID } from 'aws-sdk/clients/es'
import * as Models from '../Models'


export interface StoreSelectorState {
    stores: Array<Models.IMenuData>;
    selected: Models.IMenuData | null;
    isFetching: boolean;
    mode: actions.StoreMode;
    storeheadername: string;
}

export function processState(state: StoreSelectorState, action: actions.rpAction): StoreSelectorState {
    let outstate: StoreSelectorState = state || {
        stores: new Array<Models.IMenuData>(),
        selected: null,
        isFetching: false,
        mode: actions.StoreMode.SALES,
        storeheadername: ""
    };
    if (action == null) {
        return outstate;
    }
    switch (action.type) {
        case actions.RegoAction.SELECTSTORE:
            if (action.store != null) {
                return Object.assign({}, outstate, {
                    selected: { value: action.store.ID, label: action.store.label },
                    storeheadername: action.store.Name + " (" + action.store.Number + ")"
                });
            }
            else {
                return Object.assign({}, outstate, {
                    selected: null,
                    storeheadername: ""
                });
            }
        case actions.RegoAction.STOREOPTIONSLOADED:
            return Object.assign({}, outstate, { stores: action.stores, isFetching: false });
        case actions.RegoAction.STOREMODE:
            return Object.assign({}, outstate, { mode: action.mode });
        default:
            return outstate;
    }
}
