import React, { useEffect, useState, useReducer } from 'react';
import { IUserInfo, FormError, IFranchiseSummary, IStoreData, UserRole } from '../../Business/Models';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Tooltip, FormControl, FormGroup, InputLabel, FormHelperText, Select, MenuItem, OutlinedInput, FormControlLabel, DialogActions, Button, makeStyles, Theme, createStyles, Checkbox, TextField } from '@material-ui/core';
import { SelectFranchise } from './SelectFranchises';
import { SelectStores } from './SelectStores';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

enum AccessLevel {
    Administrator = "Administrator",
    Franchisee = "Franchisee"
}

export interface IUserCreateProps {
    open: boolean;
    submitResult: (u: IUserInfo) => void;
    cancelForm: () => void;
    franchises: Array<IFranchiseSummary>;
    stores: Array<IStoreData>;
}

export interface IUserCreateState {
    username: string;
    errors: { [id: string]: FormError };
    accessLevel: null | AccessLevel;
    franchises: Array<string>;
    stores: Array<string>;
    resetpassword: boolean;
    deactivateuser: boolean;
    confirmdeactivate: string;
    activateuser: boolean;
    changed: boolean;
}

const GetFormState: () => IUserCreateState = () => {
    return {
        username: "",
        errors: {},
        accessLevel: AccessLevel.Franchisee,
        franchises: [],
        stores: [],
        resetpassword: false,
        deactivateuser: false,
        confirmdeactivate: "",
        activateuser: false,
        changed: false
    }
}

export const UserCreateForm: React.FC<IUserCreateProps> = (props, context) => {
    const classes = useStyles();
    const [formState, setFormState] = useState<IUserCreateState>(GetFormState());

    const handleSubmit: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void = (e) => {
        let storeroles: Array<UserRole | undefined> = formState.stores.map<UserRole | undefined>((s, i) => {
            let store: IStoreData | undefined = props.stores.find(st => st.id == s);
            if (store != null) {
                return {
                    contextid: store.id,
                    contextname: store.name,
                    contexttype: "Store",
                    id: "",
                    name: formState.accessLevel || AccessLevel.Franchisee
                };
            }
        });

        let franchiseroles: Array<UserRole | undefined> = formState.franchises.map<UserRole | undefined>((f, i) => {
            let franchise: IFranchiseSummary | undefined = props.franchises.find(fr => fr.id == f);
            if (franchise != null && formState.accessLevel == AccessLevel.Administrator) {
                return {
                    contextid: franchise.id,
                    contextname: franchise.name,
                    contexttype: "Franchise",
                    id: "",
                    name: AccessLevel.Administrator
                };
            }
        });

        let userroles: Array<UserRole> = [];
        franchiseroles.forEach((fr) => {
            if (fr != null) {
                userroles.push(fr);
            }
        });
        storeroles.forEach((sr) => {
            if (sr != null) {
                userroles.push(sr);
            }
        });


        let saveuser: IUserInfo = {
            userid: "00000000-0000-0000-0000-000000000000",
            username: formState.username,
            isactive: true,
            roles: userroles
        };

        props.submitResult(saveuser);
        setFormState(GetFormState());
    }

    const handleCancel: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void = (e) => {
        setFormState(GetFormState());
        props.cancelForm();
    }

    const selectAccess: (event: React.ChangeEvent<{ name?: string | undefined, value: any }>, child: React.ReactNode) => void = (e, c) => {
        if (e != null && e.target != null) {
            setFormState({ ...formState, accessLevel: e.target.value });
        }
    }

    const selectFranchise: (franchiseId: string) => void = (fid) => {
        let ix: number | undefined = formState.franchises.indexOf(fid);
        let newfsel: Array<string> = [];
        let cursel: Array<string> = formState.franchises;
        if (ix == -1) {
            newfsel = [...cursel, fid];
        }
        else {
            cursel.splice(ix, 1);
            newfsel = cursel;
        }
        setFormState({ ...formState, franchises: newfsel });
    }

    const toggleStore: (storeid: string) => void = (storeid) => {
        let ix: number | undefined = formState.stores.indexOf(storeid);
        let newstoresel: Array<string> = [];
        let cursel: Array<string> = formState.stores;
        if (ix == -1) {
            newstoresel = [...cursel, storeid];
        }
        else {
            cursel.splice(ix, 1);
            newstoresel = cursel;
        }
        setFormState({ ...formState, stores: newstoresel });
    }

    return (
        <Dialog open={props.open} maxWidth="xs">
            <DialogTitle>Create New User</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select user's roles and request changes below.
                </DialogContentText>
                <form>
                    <Tooltip title="Add user's e-mail address" placement="right" classes={{ tooltip: classes.tooltipstyle }}>
                        <TextField id="username-input" label="User E-mail" variant="outlined" onChange={(e) => { setFormState({ ...formState, username: e.target.value });}} />
                    </Tooltip>
                    <Tooltip title="Select user privilege level" placement="right" classes={{ tooltip: classes.tooltipstyle }}>
                        <FormControl fullWidth margin='dense' variant='outlined'>
                            <InputLabel id="access-label" htmlFor='access-select'>Access Level</InputLabel>
                            <Select
                                id="access-select"
                                value={formState.accessLevel != null ? formState.accessLevel : ""}
                                onChange={selectAccess}
                                labelWidth={100}>
                                <MenuItem value={AccessLevel.Franchisee}>Franchisee</MenuItem>
                                <MenuItem value={AccessLevel.Administrator}>Administrator</MenuItem>
                            </Select>
                        </FormControl>
                    </Tooltip>
                    {formState.accessLevel == AccessLevel.Administrator ? (
                        <SelectFranchise franchises={props.franchises} selectedFranchises={formState.franchises} selectFranchise={selectFranchise} />
                    ) : null}
                    <SelectStores stores={props.stores} selectedStores={formState.stores} toggleStore={toggleStore} />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} classes={{ root: classes.savebutton }}>
                    <SaveIcon />
                </Button>
                <Button onClick={handleCancel} classes={{ root: classes.cancelbutton }}>
                    <CancelIcon />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    tooltipstyle: {
        fontSize: 'medium',
        backgroundColor: 'black'
    },
    warningstyle: {
        color: 'red',
        fontWeight: 'bold'
    },
    cancelbutton: {
        backgroundColor: 'darkred',
        '&:hover': {
            backgroundColor: 'red',
            color: 'black'
        },
        color: 'white'
    },
    savebutton: {
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'lightgreen',
            color: 'black'
        },
        color: 'white'
    }
}));