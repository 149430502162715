import { connect } from 'react-redux'
import * as RegoReducers from '../Business/Reducers/RegoReducers'
import * as StoreSelector from '../Components/Store/StoreSelector'
import * as StoreSelectorReducer from '../Business/Reducers/StoreSelectorReducer'
import * as Actions from '../Business/Reducers/Actions'
import * as Models from '../Business/Models'
import * as StoreManager from '../Business/Middleware/StoreManager'


export interface StoreSelectorDispatchProps {
    selectItem: (event: React.ChangeEvent<{ name?: string | undefined, value: any }>, child: React.ReactNode) => void;
    loadStores: () => void;
    setMode: (modename: string) => void;
}

export type StoreSelectorProps = StoreSelector.StoreSelectorStateProps & StoreSelectorDispatchProps;

export function mapStateToProps(state: any, componentprops: any): StoreSelector.StoreSelectorStateProps {
    return {
        storelist: state.storeselector != null ? state.storeselector.stores : new Array<Models.IMenuData>(),
        selectedLabel: state.storeselector != null && state.storeselector.selected != null ? state.storeselector.selected.label : "Select Store",
        activeStore: state.store != null ? state.store.activeStore : null,
        selectedItem: state.storeselector.selected,
        loadingStores: false,
        loadingWeeks: false,
        loadingSales: state.ui.saleslistFetching,
        mode: state.storeselector.mode,
        storeheadername: state.storeselector != null && state.storeselector.selected != null ? state.storeselector.storeheadername : "",
        classes: componentprops.classes
    };
}

export function mapDispatchToProps(dispatch: any): StoreSelectorDispatchProps {
    return {
        selectItem: (event, child) => {
            dispatch(() => { StoreManager.FetchStore(String(event.target.value || ""), dispatch); });
        },
        loadStores: () => {
            dispatch(() => { StoreManager.FetchStoreOptions(dispatch); })
        },
        setMode: (modename: string) => {
            let sendaction: Actions.StoreModeAction;
            switch (modename) {
                case "sales":
                    sendaction = Actions.storeMode(Actions.StoreMode.SALES);
                    break;
                case "info":
                    sendaction = Actions.storeMode(Actions.StoreMode.INFO);
                    break;
                case "dashboard":
                    sendaction = Actions.storeMode(Actions.StoreMode.DASHBOARD);
                    break;
                default:
                    sendaction = Actions.storeMode(Actions.StoreMode.SALES);
                    break;
                }    
            dispatch(sendaction);
        }        
    };
}

export const MappedStoreSelectorControl = connect<StoreSelector.StoreSelectorStateProps, StoreSelectorDispatchProps, any>(mapStateToProps, mapDispatchToProps)(StoreSelector.default)