import { Action as reduxAction } from 'redux'
import { GUID } from 'aws-sdk/clients/es';
import * as ApiRepository from '../Repositories/ApiRepository'
import { routerActions } from 'connected-react-router';
import * as Models from '../Models';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export enum StoreMode {
    DASHBOARD,
    SALES,
    INFO
}

export type rpAction =
    InitializeAction |
    OtherAction |
    SelectStoreAction |
    StoresLoadedAction |
    StoreOptionsLoadedAction |
    StoreModeAction |
    WeekendsLoadedAction |
    WeekendSelectedAction |
    WeeklySalesSubmittedAction |
    AuthenticatedAction |
    WeeklySalesListLoadedAction;

export enum RegoAction {
    INITIALIZE,
    OTHER,
    SELECTSTORE,
    STORESLOADED,
    STOREOPTIONSLOADED,
    STOREMODE,
    WEEKENDSLOADED,
    WEEKENDSELECTED,
    WEEKLYSALESUBMITTED,
    AUTHENTICATED,
    WEEKLYSALESLISTLOADED
}

export type InitializeAction = {
    type: RegoAction.INITIALIZE
}

export type OtherAction = {
    type: RegoAction.OTHER
}

export type SelectStoreAction = {
    type: RegoAction.SELECTSTORE,
    store: Models.Store | null,
    roles: Array<string> | null
}

export type StoresLoadedAction = {
    type: RegoAction.STORESLOADED,
    stores: Array<Models.Store>
}

export type StoreOptionsLoadedAction = {
    type: RegoAction.STOREOPTIONSLOADED,
    stores: Array<Models.IMenuData>
}

export type StoreModeAction = {
    type: RegoAction.STOREMODE,
    mode: StoreMode
}

export type WeekendsLoadedAction = {
    type: RegoAction.WEEKENDSLOADED,
    weeklist: Array<Models.IMenuData>
}

export type WeekendSelectedAction = {
    type: RegoAction.WEEKENDSELECTED,
    salesdata: Models.IWeeklySalesInfo
}

export type WeeklySalesSubmittedAction = {
    type: RegoAction.WEEKLYSALESUBMITTED,
    journal: Models.WeeklySalesJournal
}

export type AuthenticatedAction = {
    type: RegoAction.AUTHENTICATED,
    session: CognitoUserSession
}

export type WeeklySalesListLoadedAction = {
    type: RegoAction.WEEKLYSALESLISTLOADED,
    saleslist: Array<Models.IWeeklySalesInfo>
}

export function selectStore(newstore: Models.Store | null, roles: Array<string> | null): rpAction {
    let outAction: SelectStoreAction = {
        type:  RegoAction.SELECTSTORE,
        store: newstore, 
        roles: roles
    }
    return outAction;

}

export function storesLoaded(stores: Array<Models.Store>): rpAction {
    let outAction: StoresLoadedAction = {
        type: RegoAction.STORESLOADED,
        stores: stores
    }

    return outAction;
}

export function storeOptionsLoaded(stores: Array<Models.IMenuData>): rpAction {
    let outAction: StoreOptionsLoadedAction = {
        type: RegoAction.STOREOPTIONSLOADED,
        stores: stores
    }

    return outAction;
}

export function storeMode(mode: StoreMode) {
    let outAction: StoreModeAction = {
        type: RegoAction.STOREMODE,
        mode: mode
    }

    return outAction;
}

export function weekendsLoaded(data: Array<Models.IMenuData>) {
    let outAction: WeekendsLoadedAction = {
        type: RegoAction.WEEKENDSLOADED,
        weeklist: data
    }

    return outAction;
}

export function weekendSelected(salesInfo: Models.IWeeklySalesInfo) {
    let outAction: WeekendSelectedAction = {
        type: RegoAction.WEEKENDSELECTED,
        salesdata: salesInfo
    }
    return outAction;
}

export function weeklySalesSubmitted(data: Models.WeeklySalesJournal) {
    let outAction: WeeklySalesSubmittedAction = {
        type: RegoAction.WEEKLYSALESUBMITTED,
        journal: data
    }

    return outAction;
}

export function Authenticated(session: CognitoUserSession, token: string) {
    let outAction: AuthenticatedAction = {
        type: RegoAction.AUTHENTICATED,
        session: session
    }

    return outAction;
}

export function weeklySalesListLoaded(data: Array<Models.IWeeklySalesInfo>) {
    let outAction: WeeklySalesListLoadedAction = {
        type: RegoAction.WEEKLYSALESLISTLOADED,
        saleslist: data
    }

    return outAction;
}

