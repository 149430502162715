import * as React from 'react'
import * as StoreSalesMapping from '../../Mappings/StoreSalesMapping'
import * as StoreSalesReducer from '../../Business/Reducers/StoreSalesReducer'
import * as Models from '../../Business/Models'
import * as SalesForm from './WeeklySalesForm'
import { LinearProgress, Table, TableBody, TableHead, TableCell, TableRow, TablePagination, Paper, Icon, Theme, withStyles, createStyles, WithStyles, Button, Fab } from '@material-ui/core'
import classNames from 'classnames'
import {green} from '@material-ui/core/colors'
import { Repository } from '../../Business/Repositories/ApiRepository';

interface localstate {
    dialogopen: boolean
}

export interface StoreSalesStateProps extends WithStyles<typeof styles> {
    selectedStore: Models.Store;
    weeklist: Array<Models.IMenuData>;
    selectedWeek: Models.IWeeklySalesInfo | null;
    weeklySalesList: Array<Models.IWeeklySalesInfo>;
    salesloading: boolean;
    roles: Array<string>;
}


class StoreSalesPage extends React.Component<StoreSalesMapping.StoreSalesProps, localstate>{
    constructor(props: StoreSalesMapping.StoreSalesProps, state?: localstate) {
        super(props);
    }

    componentWillMount() {
    }

    componentWillUpdate() {
    }

    hideDialog(): void {
        this.setState({ dialogopen: false });
    }

    showDialog(): void {
        this.setState({ dialogopen: true });
    }
    
    render() {
        const { classes, roles } = this.props;
        let s: Models.Store = this.props.selectedStore;
        let today: Date = new Date();
        let isAdmin: boolean = roles.indexOf("Administrator") >= 0;
        const todayKey: number = (today.getFullYear() * 10000) + ((today.getMonth() + 1) * 100) + today.getDate();
        if (s != null) {  
            return (
                <div className="store sales">                    
                    <div className="leftpanel">
                        <div className="salesdata">
                            {this.props.salesloading && <LinearProgress color='secondary' variant='indeterminate' />}
                            <Paper>
                                <Table className="weeklysaleslist">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell classes={{ head: classes.datecellheader }}>Week Ending</TableCell>
                                            <TableCell classes={{ head: classes.cellheader }}>Gross</TableCell>
                                            <TableCell classes={{ head: classes.cellheader }}>Net</TableCell>
                                            <TableCell classes={{ head: classes.cellheader }}>Transactions</TableCell>
                                            <TableCell classes={{ head: classes.cellheader }}>Food</TableCell>
                                            <TableCell classes={{ head: classes.cellheader }}>Labor</TableCell>
                                            <TableCell classes={{ head: classes.cellheader }}>Paper</TableCell>
                                            <TableCell classes={{ head: classes.contentcellheader }}>Recorded</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.weeklySalesList.map((d, i) => {
                                            let datestring: string = d.weekEndDate.substr(5, 2) + "/" + d.weekEndDate.substr(8, 2) + "/" + d.weekEndDate.substr(0, 4);
                                            let recdate: string | null = d.lastUpdate != null ? d.lastUpdate.toString() : null;
                                            let recorddatestring: string | null = recdate != null ? recdate.substr(5, 2) + "/" + recdate.substr(8, 2) + "/" + recdate.substr(0, 4) + " " + recdate.substr(11, 8) : null;
                                            let isselected: boolean = this.props.selectedWeek != null && this.props.selectedWeek.weekDateKey == d.weekDateKey;
                                            let canmanuallyenter: boolean = isselected && d.weekDateKey < todayKey;
                                            let usejournal: boolean = d.latestSalesJournal != null && (d.lastUpdate == null || d.lastUpdate < d.latestSalesJournal.recordDate);
                                        
                                            return (
                                                <TableRow hover selected={isselected} key={i} onClick={() => { if (isselected == false) { this.props.selectWeek(d); }}}>
                                                    <TableCell classes={{root: classes.datecell}}>
                                                        {isselected &&
                                                            <Icon fontSize='small' color='error'>chevron_right</Icon>
                                                        }
                                                        {datestring}
                                                    </TableCell>
                                                    <TableCell className={classNames(classes.numbercell)} classes={{ root: classes.cellborder }}>{d.gross != null ? "$" + d.gross.toFixed(2) : ""}</TableCell>
                                                    <TableCell className={classNames(classes.numbercell)} classes={{ root: classes.cellborder }}>{d.net != null ? "$" + d.net.toFixed(2) : ""}</TableCell>
                                                    <TableCell className={classNames(classes.numbercell)} classes={{ root: classes.cellborder }}>{d.transactionCount != null ? d.transactionCount : d.latestSalesJournal != null ? d.latestSalesJournal.transactionCount : ""}</TableCell>
                                                    <TableCell className={classNames(classes.numbercell)} classes={{ root: classes.cellborder }}>{d.foodCostRate != null ? (d.foodCostRate * 100).toFixed(4) + "%" : ""}</TableCell>
                                                    <TableCell className={classNames(classes.numbercell)} classes={{ root: classes.cellborder }}>{d.laborCostRate != null ? (d.laborCostRate * 100).toFixed(4) + "%" : ""}</TableCell>
                                                    <TableCell className={classNames(classes.numbercell)} classes={{ root: classes.cellborder }}>{d.paperCostRate != null ? (d.paperCostRate * 100).toFixed(4) + "%" : ""}</TableCell>
                                                    <TableCell className={classNames(classes.contentcell)} classes={{ root: classes.cellborder }}>{recorddatestring != null ? (<div><span>{recorddatestring}</span> <br /> <span>{d.updatedBy}</span></div>)  : (<div><span>&nbsp;</span><br /><span>&nbsp;</span></div>)}
                                                        {canmanuallyenter && d.gross == null && (
                                                        <Fab size='small' classes={{ root: classes.enterdatabutton }} onClick={() => { this.showDialog(); }}>$</Fab>
                                                        )}
                                                        {(canmanuallyenter && isAdmin && isselected && d.gross != null) && (
                                                        <Fab size='small' classes={{ root: classes.enterdatabutton }} onClick={() => { this.showDialog(); }}>Edit</Fab>
                                                        )}
                                                    </TableCell>                                                    
                                                </TableRow>
                                            );}
                                        )}
                                    </TableBody>
                                </Table>
                                {this.props.selectedWeek != null &&
                                    <SalesForm.default open={this.state == null ? false : this.state.dialogopen} handleHide={() => { this.hideDialog(); }} callback={this.props.submitWeeklySales} storeid={s.ID} selectedweek={this.props.selectedWeek} />
                                }
                            </Paper>
                        </div>
                    </div>
                    {/*
                    <div className="rightpanel">
                        {this.props.selectedWeek != null && new Date(this.props.selectedWeek.weekEndDate) < new Date() ?
                            <Segment className="paymentstatus">
                                <Header attached='top' block>
                                    Status
                                </Header>
                                <Segment attached='bottom'>
                                    { this.props.selectedWeek.gross == null && this.props.selectedWeek ? "Ready" : "Pending" }
                                </Segment>
                                {this.props.selectedWeek.recordDate != null ?
                                    <Container>
                                        <Header attached='top' block>Last Update</Header>
                                        <Segment attached='bottom'>{this.props.selectedWeek.recordDate.substr(5, 2) + "/" + this.props.selectedWeek.recordDate.substr(8, 2) + "/" + this.props.selectedWeek.recordDate.substr(0, 4)}</Segment>
                                        <Header attached='top' block>Updated By</Header>
                                        <Segment attached='bottom'>{this.props.selectedWeek.addedBy}</Segment>
                                    </Container>
                                    : null}
                                {this.props.selectedWeek != null && (this.props.selectedWeek.gross == null || this.props.selectedWeek.gross == 0) && new Date(this.props.selectedWeek.weekEndDate) < new Date() ?
                                    <SalesForm.WeeklySalesForm weekdatekey={this.props.selectedWeek.weekDateKey} endofweekdate={new Date(this.props.selectedWeek.weekEndDate)} storeid={s.ID} callback={this.props.submitWeeklySales} />
                                        : null}
                                
                            </Segment>
                            : null}
                    </div>
                    */}
                </div>
            );
        }
        else {
            return null;
        }
    }
}

const styles = (theme: Theme) => createStyles({
    cellheader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        maxWidth: 90,
        width: 90,
        textAlign: 'center',
        padding: 5,
        '&:last-child': {
            padding: 5
        }
    },
    contentcellheader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        maxWidth: 200,
        width: 200,
        minWidth: 200,
        textAlign: 'center',
        padding: 5,
        '&:last-child': {
            padding: 5
        }
    },
    datecellheader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        maxWidth: 125,
        minWidth: 125,
        width: 125,
        textAlign: 'center',
        padding: 5,
        '&:last-child': {
            padding: 5
        }
    },
    cellborder: {
        borderRight: `1px solid ${theme.palette.divider}`,
        padding: 5,
        '&:last-child': {
            padding: 5
        }
    },
    numbercell: {
        textAlign: 'right',
        fontFamily: 'Courier New'
    },
    datecell: {
        borderRight: `1px solid ${theme.palette.divider}`,
        maxWidth: 125,
        minWidth: 125,
        padding: 5,
        textAlign: 'center'
    },
    contentcell: {
        borderRight: `1px solid ${theme.palette.divider}`,
        maxWidth: 200,
        width: 200,
        minWidth: 200,
        padding: 5,
        textAlign: 'center'
    },
    enterdatabutton: {
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'lightgreen',
            color: 'black'
        },
        color: 'white',
        position: 'absolute',
        marginTop: -40,
        marginLeft: 100
    }
});

export default withStyles(styles)(StoreSalesPage);