import React, { useEffect, useState, useReducer } from 'react';
import { Tooltip, FormControl, FormGroup, InputLabel, FormHelperText, Select, MenuItem, OutlinedInput, FormControlLabel, DialogActions, Button, makeStyles, Theme, createStyles, Checkbox } from '@material-ui/core';
import { IUserInfo, FormError, IFranchiseSummary, IStoreData, UserRole } from '../../Business/Models';

export interface ISelectFranchiseProps {
    franchises: Array<IFranchiseSummary>;
    selectedFranchises: Array<string>;
    selectFranchise: (code: string) => void;
}

export const SelectFranchise: React.FC<ISelectFranchiseProps> = (props, context) => {
    const classes = useStyles();

    return (
        <Tooltip title="Select franchise or franchises to associate user with" placement="right" classes={{ tooltip: classes.tooltipstyle }}>
            <FormGroup>
                {props.franchises.map((f, i) => {
                    return (
                        <FormControlLabel
                            control={
                                <Checkbox value={f.id} checked={props.selectedFranchises.indexOf(f.id) != -1} onChange={(e, c) => { props.selectFranchise(f.id); }} />
                            }
                            label={f.name}
                        />
                    );
                })}
            </FormGroup>
        </Tooltip>
    );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    tooltipstyle: {
        fontSize: 'medium',
        backgroundColor: 'black'
    },
    warningstyle: {
        color: 'red',
        fontWeight: 'bold'
    }
}));