import * as React from 'react'
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormControlLabel, Tooltip, Input, FilledInput, OutlinedInput, InputLabel, Button, InputAdornment, Theme, WithStyles, withStyles, createStyles, Checkbox} from '@material-ui/core'
import * as Models from '../../Business/Models'
import { Popup } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { WeeklySalesFormState, FormError, PopulateWeeklySalesFormState } from '../../Business/StateModels'

export interface ISalesEntryProperties extends WithStyles<typeof styles> {
    open: boolean,
    handleHide: () => void;
    storeid: string;
    selectedweek: Models.IWeeklySalesInfo,
    callback: (data: WeeklySalesFormState) => void;
}

class WeeklySalesForm extends React.Component<ISalesEntryProperties, WeeklySalesFormState> {    
    constructor(props: ISalesEntryProperties, state?: WeeklySalesFormState) {
        super(props);

        if (props.selectedweek != null) {
            this.state = PopulateWeeklySalesFormState(props.storeid, props.selectedweek);
        }
        else {
            this.state = {
                storeid: props.storeid,
                selectedweek: null,
                weekdatekey: -1,
                endofweekdate: null,
                gross: "",
                net: "",
                adjustednet: "",
                transactioncount: "",
                foodcostrate: "",
                laborcostrate: "",
                papercostrate: "",
                errors: {
                    "gross": { errorstate: false, errormsg: "" },
                    "net": { errorstate: false, errormsg: "" },
                    "adjustednet": { errorstate: false, errormsg: "" },
                    "transactioncount": { errorstate: false, errormsg: "" },
                    "foodcostrate": { errorstate: false, errormsg: "" },
                    "laborcostrate": { errorstate: false, errormsg: "" },
                    "papercostrate": { errorstate: false, errormsg: "" },
                },
                requireconfirmation: false,
                confirmed: false
            };
        }
    }

    handleMoneyChange = (field: string, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {  
        var regex = /^\d*((\.)\d{0,2})?$/;
        if (regex.test(e.target.value)) {
            this.setState(Object.assign({}, this.state, { [field]: e.target.value }));
        }
        /*
        var regexfull = /^\d*((\.)\d{2})?$/;
        if (regex.test(e.target.value) == false) {
        }
        */
    }
    handlePercentChange = (field: string, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        var regex = /^\d*((\.)\d{0,2})?$/;
        if (regex.test(e.target.value)) {
            this.setState(Object.assign({}, this.state, { [field]: e.target.value }));
        }
    }
    handleCountChange = (field: string, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        var regex = /^[0-9]*$/;
        if (regex.test(e.target.value)) {
            this.setState(Object.assign({}, this.state, { [field]: e.target.value }));
        }
    }

    checknumber(val: any): boolean {
        if (isNullOrUndefined(val) || isNaN(val) || Number(val) <= 0) {
            return false;
        }
        return true;
    }

    validate(): boolean {
        let formvals = this.state;
        let resultcode: boolean = true;
        var errs: any = {
            "gross": { errorstate: false, errormsg: "" },
            "net": { errorstate: false, errormsg: "" },
            "adjustednet": { errorstate: false, errormsg: "" },
            "transactioncount": { errorstate: false, errormsg: "" },
            "foodcostrate": { errorstate: false, errormsg: "" },
            "laborcostrate": { errorstate: false, errormsg: "" },
            "papercostrate": { errorstate: false, errormsg: "" },
        };
        if (this.checknumber(formvals.gross) == false) {
            errs["gross"].errorstate = true;
            resultcode = false;
        }
        else {
            errs["gross"].errorstate = false;
        }
        if (this.checknumber(formvals.net) == false) {
            errs["net"].errorstate = true;
            resultcode = false;
        }
        else {
            errs["net"].errorstate = false;
        }
        if (this.checknumber(formvals.transactioncount) == false) {
            errs["transactioncount"].errorstate = true;
            resultcode = false;
        }
        else {
            errs["transactioncount"].errorstate = false;
        }

        if (resultcode == false) {
            this.setState({ errors: errs });
        }
        else {
            if (this.secondaryValidate()) {
                this.props.handleHide();
            }
            else {
                this.setState({ requireconfirmation: true });
                resultcode = false;
            }
        }

        return resultcode;
    }

    secondaryValidate(): boolean {
        let formvals: Readonly<WeeklySalesFormState> = this.state;
        let resultcode: boolean = true;
        let gross: number = Number(formvals.gross);
        let net: number = Number(formvals.net);

        if (net > gross
            || net * 1.12 <= gross) {
            resultcode = false;
        }

        return resultcode || formvals.confirmed;
    }

    handleSubmit = (e: React.FormEvent<HTMLElement>) => {
        if (this.validate()) {
            this.props.callback(this.state);
        }
    }

    handleCancel = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        this.props.handleHide();
        this.setState({
            gross: "",
            net: "",
            adjustednet: "",
            transactioncount: "",
            foodcostrate: "",
            laborcostrate: "",
            papercostrate: "",
            errors: {
                "gross": { errorstate: false, errormsg: "" },
                "net": { errorstate: false, errormsg: "" },
                "adjustednet": { errorstate: false, errormsg: "" },
                "transactioncount": { errorstate: false, errormsg: "" },
                "foodcostrate": { errorstate: false, errormsg: "" },
                "laborcostrate": { errorstate: false, errormsg: "" },
                "papercostrate": { errorstate: false, errormsg: "" },
            }
        });
    }

    handleConfirm = (e: React.FormEvent<HTMLInputElement>, val: boolean) => {
        this.setState({ confirmed: val });
    }

    componentWillReceiveProps(nextProps: ISalesEntryProperties) {
        this.setState(PopulateWeeklySalesFormState(nextProps.storeid, nextProps.selectedweek));
    }

    handleOpen = () => {
        this.setState(PopulateWeeklySalesFormState(this.props.storeid, this.props.selectedweek));
    }

    render() {
        const { classes } = this.props;
        let datestr: string = this.state.endofweekdate != null ? this.state.endofweekdate.toDateString() : "";
        return (
            <Dialog open={this.props.open} maxWidth="xs">
                <DialogTitle>Sales Data: {datestr}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter your sales data for Week Ending {datestr} in the form below.  Once complete, click the "Submit" button.
                    </DialogContentText>
                    <form>
                        <Tooltip title="Gross sales before any deductions." placement="right" classes={{ tooltip: classes.tooltipstyle}}>                           
                            <FormControl fullWidth error={this.state.errors["gross"].errorstate} margin='dense' variant='outlined'>
                                <InputLabel htmlFor="gross-sales">Gross Sales</InputLabel>
                                <OutlinedInput labelWidth={80} id="gross-sales" value={this.state.gross} onChange={(e) => this.handleMoneyChange("gross", e)} startAdornment={<InputAdornment position="start">$</InputAdornment>} />
                            </FormControl>
                        </Tooltip>
                        <Tooltip title="Sales after subtracting coupons and discounts." placement="right" classes={{ tooltip: classes.tooltipstyle }}>    
                            <FormControl fullWidth error={this.state.errors["net"].errorstate} margin='dense' variant='outlined'>
                                <InputLabel htmlFor="net-sales">Net Sales</InputLabel>
                                <OutlinedInput labelWidth={70} id="net-sales" value={this.state.net} onChange={(e) => this.handleMoneyChange("net", e)} startAdornment={<InputAdornment position="start">$</InputAdornment>} />
                            </FormControl>
                        </Tooltip>
                        <Tooltip title="Number of sales transactions." placement="right" classes={{ tooltip: classes.tooltipstyle }}> 
                            <FormControl fullWidth error={this.state.errors["transactioncount"].errorstate} margin='dense' variant='outlined'>
                                <InputLabel htmlFor="transactioncount">Transaction Count</InputLabel>
                                <OutlinedInput labelWidth={120} id="transactioncount" value={this.state.transactioncount} onChange={(e) => this.handleCountChange("transactioncount", e)} />
                            </FormControl>
                        </Tooltip>
                        <Tooltip title="Percentage amount spent on food costs." placement="right" classes={{ tooltip: classes.tooltipstyle }}> 
                            <FormControl fullWidth error={this.state.errors["foodcostrate"].errorstate} margin='dense' variant='outlined'>
                                <InputLabel htmlFor="foodcostrate">Food Cost %</InputLabel>
                                <OutlinedInput labelWidth={80} id="foodcostrate" value={this.state.foodcostrate} onChange={(e) => this.handlePercentChange("foodcostrate", e)} endAdornment={<InputAdornment position="end">%</InputAdornment>} />
                            </FormControl>
                        </Tooltip>
                        <Tooltip title="Percentage amount spent on labor costs." placement="right" classes={{ tooltip: classes.tooltipstyle }}> 
                            <FormControl fullWidth error={this.state.errors["laborcostrate"].errorstate} margin='dense' variant='outlined'>
                                <InputLabel htmlFor="laborcostrate">Labor Cost %</InputLabel>
                                <OutlinedInput labelWidth={80} id="laborcostrate" value={this.state.laborcostrate} onChange={(e) => this.handlePercentChange("laborcostrate", e)} endAdornment={<InputAdornment position="end">%</InputAdornment>} />
                            </FormControl>
                        </Tooltip>
                        <Tooltip title="Percentage amount spent on paper goods." placement="right" classes={{ tooltip: classes.tooltipstyle }}> 
                            <FormControl fullWidth error={this.state.errors["papercostrate"].errorstate} margin='dense' variant='outlined'>
                                <InputLabel htmlFor="papercostrate">Paper Cost %</InputLabel>
                                <OutlinedInput labelWidth={80} id="papercostrate" value={this.state.papercostrate} onChange={(e) => this.handlePercentChange("papercostrate", e)} endAdornment={<InputAdornment position="end">%</InputAdornment>} />
                            </FormControl>
                        </Tooltip>
                        {this.state.requireconfirmation ?
                            <FormControlLabel control={<Checkbox checked={this.state.confirmed} onChange={(e, val) => this.handleConfirm(e, val)} />} classes={{ label: classes.warningstyle }} label="Please review Gross and Net values and confirm they are correct."></FormControlLabel>
                            : null}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => { this.handleCancel(e); }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={(e) => { this.handleSubmit(e); }} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = (theme: Theme) => createStyles({
    tooltipstyle: {
        fontSize: 'medium',
        backgroundColor: 'black'
    },
    warningstyle: {
        color: 'red',
        fontWeight: 'bold'
    }
});

export default withStyles(styles)(WeeklySalesForm);