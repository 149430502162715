import * as React from 'react'
import * as StoreHomeMapping from '../../Mappings/StoreHomeMapping'
import * as StoreReducer from '../../Business/Reducers/StoreReducer'
import { StoreMode } from '../../Business/Reducers/Actions'
import { MappedStoreSalesPage } from '../../Mappings/StoreSalesMapping'

export class StoreHomePage extends React.Component<StoreHomeMapping.StoreHomeProps, StoreReducer.StoreState>{
    constructor(props: StoreHomeMapping.StoreHomeProps, state: StoreReducer.StoreState) {
        super(props);
    }

    render() {
        switch (this.props.mode) {
            case StoreMode.SALES:
                return (
                    <MappedStoreSalesPage />
                );
            case StoreMode.INFO:
                return (
                    <div></div>
                );
            case StoreMode.DASHBOARD:
                return (
                    <div></div>
                );
            default:
                return null;
        }
    }
}
