import * as React from 'react'
import * as StoreSelectorMapping from '../../Mappings/StoreSelectorMapping'
import * as StoreSelectorReducer from '../../Business/Reducers/StoreSelectorReducer'
import * as Models from '../../Business/Models'
import {StoreMode} from '../../Business/Reducers/Actions'
import { Select, MenuItem, Typography, FormControl, InputLabel, FilledInput } from '@material-ui/core'
import {WithStyles, withStyles, createStyles, Theme} from '@material-ui/core'

export interface StoreSelectorStateProps extends WithStyles<typeof styles> {
    storelist: Array<Models.IMenuData>;
    selectedLabel: string | null;
    activeStore: Models.Store | null;
    selectedItem: Models.IMenuData | null;
    loadingStores: boolean;
    loadingWeeks: boolean;
    loadingSales: boolean;
    mode: StoreMode;
    storeheadername: string;
}


class StoreSelectorControl extends React.Component<StoreSelectorMapping.StoreSelectorProps, StoreSelectorReducer.StoreSelectorState>{
    constructor(props: StoreSelectorMapping.StoreSelectorProps, state?: StoreSelectorReducer.StoreSelectorState) {
        super(props);
    }

    componentWillMount() {
        this.props.loadStores();
    }

    render() {
        const { classes } = this.props;
        if (this.props != null && this.props.storelist != null) {
            return (<div className="storeselector topbar">
                <FormControl variant='filled'>
                    <InputLabel htmlFor='store-selector'>Store</InputLabel>
                    <Select
                        input={<FilledInput name='selectedstore' id="store-selector" />}
                        value={this.props.selectedItem != null ? this.props.selectedItem.value : ''}
                        onChange={this.props.selectItem}
                        inputProps={{ name: 'storeid', id: 'storeselector' }}>
                        {this.props.storelist.map((itm, ix) => (
                            <MenuItem key={ix} value={itm.value}>
                                {itm.text}
                            </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <Typography variant='h4' classes={{root: classes.storenameheading }}>{this.props.storeheadername}</Typography>
                </div>
            );
        }
        else {
            return (
                <div>yaya</div>
            );
        }
        return null;
    }
}

const styles = (theme: Theme) => createStyles({
    storenameheading: {
        paddingTop: 10,
        paddingLeft: 10
    }
});

export default withStyles(styles)(StoreSelectorControl)