import * as redux from 'redux'
import * as actions from '../Reducers/Actions'
import * as Models from '../Models'
import * as ApiRepository from '../Repositories/ApiRepository'

export function FetchStore(id: string, dispatch: any): void {
    let identitytoken = localStorage.getItem("identityToken") || "";
    let repo: ApiRepository.Repository = new ApiRepository.Repository(identitytoken);

    repo.FetchStore(id, (s, r) => {
        FetchStoreWeeklySales(id, dispatch);
        //FetchWeekEndOptions(id, dispatch);
        dispatch(actions.selectStore(s, r));
    });
}

export function FetchStores(dispatch: any): void {
    let accesstoken = localStorage.getItem("identityToken") || "";
    let repo: ApiRepository.Repository = new ApiRepository.Repository(accesstoken);

    repo.FetchStores((s) => {
        dispatch(actions.storesLoaded(s));
    });
}

export function FetchStoreOptions(dispatch: any): void {
    let accesstoken = localStorage.getItem("identityToken") || "";
    let repo: ApiRepository.Repository = new ApiRepository.Repository(accesstoken);

    repo.FetchStoreOptions((s) => {
        dispatch(actions.storeOptionsLoaded(s));
    });
}

export function FetchStoreWeeklySales(storeid: string, dispatch: any): void {
    let accesstoken = localStorage.getItem("identityToken") || "";
    let repo: ApiRepository.Repository = new ApiRepository.Repository(accesstoken);

    repo.FetchStoreWeeklySales(storeid, (s: Array<Models.IWeeklySalesInfo>) => {
        dispatch(actions.weeklySalesListLoaded(s));
    });
}

export function FetchWeekEndOptions(storeid: string, dispatch: any) {
    let accesstoken = localStorage.getItem("identityToken") || "";
    let repo: ApiRepository.Repository = new ApiRepository.Repository(accesstoken);

    repo.FetchWeekEndOptions(storeid, (l: Array<Models.IMenuData>) => {
        dispatch(actions.weekendsLoaded(l));
    });
}

export function SaveWeeklySalesLog(data: Models.WeeklySalesJournal, dispatch: any): void {
    let accesstoken = localStorage.getItem("identityToken") || "";
    let repo: ApiRepository.Repository = new ApiRepository.Repository(accesstoken);

    repo.PostWeeklySalesJournal(data, () => {
        //dispatch(actions.weeklySalesSubmitted(i));
        repo.FetchStoreWeeklySales(data.StoreID, (s: Array<Models.IWeeklySalesInfo>) => {
            dispatch(actions.weeklySalesListLoaded(s));
        });
    });
}