import React, { useEffect, useState, useReducer, useContext } from 'react';
import { UserList } from './UserList';
import { UserInfo, IUserInfo, IStoreData, IFranchiseSummary } from '../../Business/Models';
import { Repository } from '../../Business/Repositories/ApiRepository';

export interface IUserHomeProps {

}

export interface IUserHomeState {
    Mode: UserMode,
    users: Array<UserInfo>
}

enum UserMode {
    List = "LIST"
}

const GetDefaultUserHomeState: () => IUserHomeState = () => {
    return {
        Mode: UserMode.List,
        users: [],
        stores: [],
        franchises: []
    };
}

export const UserHome: React.FC<IUserHomeProps> = (props, context) => {
    let [userState, setUserState] = useState(GetDefaultUserHomeState());
    let [stores, setStores] = useState<Array<IStoreData>>([]);
    let [franchises, setFranchises] = useState<Array<IFranchiseSummary>>([]);

    let accesstoken = localStorage.getItem("identityToken") || "";
    const datasrc: Repository = new Repository(accesstoken);

    const refreshUserList: () => void = () => {
        datasrc.FetchUsers((data) => {
            setUserState({ ...userState, users: data });
        });
    };

    const refreshFranchises: () => void = () => {
        datasrc.FetchFranchiseSummaries((data) => {
            setFranchises(data);
        });
    };

    const saveUserData: (usr: IUserInfo) => void = (usr) => {
        datasrc.PostUserInfo(usr, refreshUserList);
    }

    const refreshStores: () => void = () => {
        datasrc.FetchStoreSummaries((data) => {
            setStores(data);
        });
    };

    const performUserAction: (user: IUserInfo, action: string) => void = (u, a) => {
        datasrc.RunUserAction(u.userid, a, refreshUserList);
    }

    useEffect(() => {
        refreshUserList();
    }, []);

    useEffect(() => {
        refreshFranchises();
    }, []);

    useEffect(() => {
        refreshStores();
    }, []);

    switch (userState.Mode) {
        case UserMode.List:
            return (
                <UserList performAction={performUserAction} refreshUsers={refreshUserList} userData={userState.users} storeData={stores} franchiseData={franchises} saveUserData={saveUserData}></UserList>
            );
        default:
            return (
                <div></div>
                );
    }
}