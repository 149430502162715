import * as redux from 'redux'
import * as actions from './Actions'
import * as Models from '../Models'
import * as ApiRepository from '../Repositories/ApiRepository'

export interface StoreState {
    mode: actions.StoreMode;
    isFetching: boolean;
}


export function processState(state: StoreState, action: actions.rpAction): StoreState {
    let outstate = state || { mode: actions.StoreMode.SALES, isFetching: false };
    if (action == null) {
        return outstate;
    }

    switch (action.type) {
        case actions.RegoAction.SELECTSTORE:
            if (action.store != null) {
                return Object.assign({}, outstate, { isFetching: false });
            }
            else {
                return Object.assign({}, outstate, { isFetching: false });
            }
        case actions.RegoAction.STOREMODE:
            return Object.assign({}, outstate, { mode: action.mode });
        default:
            return outstate;
    }
}
