import * as actions from './Actions'
import * as Models from '../Models'

export interface StoreSalesState {
    activeStore: Models.Store;
    weeklist: Array<Models.IMenuData>;
    selectedWeek: Models.ISalesData;
    storeWeeklySales: Array<Models.SalesDataForWeek>;
    roles: Array<string>;
}

export const defaultStoreSalesState = {
    activeStore: undefined,
    weeklist: [],
    selectedWeek: null,
    storeWeeklySales: [],
    roles: []
}

export function processState(state: StoreSalesState, action: actions.rpAction): StoreSalesState {
    let outstate: StoreSalesState = state || defaultStoreSalesState;
    switch (action.type) {
        case actions.RegoAction.SELECTSTORE:
            return Object.assign({}, outstate, { activeStore: action.store, storeWeeklySales: [], selectedWeek: null, roles: action.roles});
        case actions.RegoAction.WEEKENDSLOADED:
            return Object.assign({}, outstate, { weeklist: action.weeklist, selectedWeek: null });
        case actions.RegoAction.WEEKENDSELECTED:
            return Object.assign({}, outstate, { selectedWeek: action.salesdata });
        case actions.RegoAction.WEEKLYSALESLISTLOADED:
            return Object.assign({}, outstate, { storeWeeklySales: action.saleslist, selectedWeek: null});
        default:
            break;
    }
    return outstate;
}