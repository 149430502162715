import * as React from 'react'
import * as HomeBaseMapping from '../../Mappings/HomeBaseMapping'
import * as HomeBaseReducer from '../../Business/Reducers/HomeBaseReducer'
import * as Models from '../../Business/Models'
import {Grid, Paper, Card, CardContent, Theme, withStyles, WithStyles, createStyles} from '@material-ui/core'



interface localstate {
}

export interface HomeBaseStateProps extends WithStyles<typeof styles> {
    isfetching: boolean;
}
class HomeBasePage extends React.Component<HomeBaseMapping.HomeBaseProps, localstate>{
    constructor(props: HomeBaseMapping.HomeBaseProps, state?: localstate) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container spacing={3} className={classes.grid}>
                    <Grid item xs={12} className={classes.gridcell}>
                            <img src={process.env.PUBLIC_URL + '/img/logo_rego.png'} width={400}/>
                    </Grid>
                    <Grid item xs={12} sm={8} className={classes.gridcellsm}>
                        <Paper className={classes.paper}>
                                <img src={process.env.PUBLIC_URL + '/img/logo_tdm.png'} width={250} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={8} className={classes.gridcellsm}>
                        <Paper className={classes.paper}>
                            <img src={process.env.PUBLIC_URL + '/img/logo_quiznos.png'} width={250} />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            );
    }
}

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '100%',
    },
    grid: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    gridcell: {
        textAlign: 'center',
    },
    gridcellsm: {
        display: 'flex',
        justifyContent: 'center',
    },
});


export default withStyles(styles)(HomeBasePage);