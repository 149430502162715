import React, { useEffect, useState, useReducer } from 'react';
import { UserInfo, IUserInfo, IFranchiseSummary, IStoreData } from '../../Business/Models';
import { LinearProgress, Table, TableBody, TableHead, TableCell, TableRow, TablePagination, Paper, Icon, Theme, withStyles, createStyles, Grid, Button, ButtonGroup, makeStyles } from '@material-ui/core';

import { UserEditForm } from './UserEditForm';
import { UserCreateForm } from './UserCreateForm';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';

import classNames from 'classnames';

export interface IUserListProps {
    userData: Array<UserInfo>;
    franchiseData: Array<IFranchiseSummary>;
    storeData: Array<IStoreData>;
    saveUserData: (user: IUserInfo) => void;
    refreshUsers: () => void;
    performAction: (user: IUserInfo, action: string) => void;
}

interface IUserListState {
    editUserOpen: boolean;
    createUserOpen: boolean;
    selectedUser: UserInfo | null;
}


export const UserList: React.FC<IUserListProps> = (props, context) => {
    const classes = useStyles();    
    let [listState, setListState] = useState<IUserListState>({ editUserOpen: false, createUserOpen: false, selectedUser: null });

    const openEdit: (u: UserInfo) => void = (u) => {
        setListState({ ...listState, editUserOpen: true, selectedUser: u });
    }

    const openCreate: () => void = () => {
        setListState({ ...listState, createUserOpen: true, selectedUser: null });
    }

    const cancelCreate: () => void = () => {
        setListState({ ...listState, createUserOpen: false });
    }

    const cancelEdit: () => void = () => {
        setListState({ ...listState, editUserOpen: false, selectedUser: null });
    }

    const updateUser: (user: IUserInfo) => void = (u) => {
        props.saveUserData(u);
        setListState({ ...listState, editUserOpen: false, createUserOpen: false, selectedUser: null });
    }

    const performUserAction: (user: IUserInfo, action: string) => void = (u, a) => {

    }

    return (
        <div>
            {listState.selectedUser != null ?
                <UserEditForm open={listState.editUserOpen}
                    submitResult={updateUser}
                    cancelForm={cancelEdit}
                    user={listState.selectedUser}
                    franchises={props.franchiseData}
                    stores={props.storeData}/>
                : null}
            <UserCreateForm open={listState.createUserOpen}
                submitResult={updateUser}
                cancelForm={cancelCreate}
                franchises={props.franchiseData}
                stores={props.storeData} />
            <Paper>
                <Grid container spacing={2}>                   
                    <Grid item xs={12} container>
                    <Grid item>
                        
                    </Grid>
                    <Grid item>
                        <ButtonGroup>
                            <Button onClick={() => { props.refreshUsers(); }} color='primary' variant='contained'><RefreshIcon /></Button>
                            <Button onClick={() => { openCreate(); }} color='primary' variant='contained'><AddIcon /></Button>
                        </ButtonGroup>
                    </Grid>
                    </Grid>
                    <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow classes={{}}>
                            <TableCell classes={{ head: classes.cellheader }}>User</TableCell>
                            <TableCell classes={{ head: classes.cellheader }}>IsActive</TableCell>
                            <TableCell classes={{ head: classes.cellheader }}>Franchises</TableCell>
                            <TableCell classes={{ head: classes.cellheader }}>Stores</TableCell>
                            <TableCell classes={{ head: classes.cellheader }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.userData.map((d, i) => {
                                return (
                                    <TableRow>
                                        <TableCell className={classNames(classes.contentcell)} classes={{ root: classes.cellborder }}>{d.username}</TableCell>
                                        <TableCell className={classNames(classes.contentcell)} classes={{ root: classes.cellborder }}>{d.isactive ? "Active" : "Inactive"}</TableCell>
                                        <TableCell className={classNames(classes.contentcell)} classes={{ root: classes.cellborder }}>
                                            {d.roles.filter((r) => r.contexttype == "Franchise").map((f) => { return f.contextname }).join(",")}
                                        </TableCell>
                                        <TableCell className={classNames(classes.contentcell)} classes={{ root: classes.cellborder }}>
                                            {d.roles.filter((r) => r.contexttype == "Store").map((s) => { return s.contextname }).join(",")}
                                        </TableCell>   
                                        <TableCell className={classNames(classes.contentcell)} classes={{ root: classes.cellborder }}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                            <ButtonGroup>
                                                        <Button variant='contained' size='small' classes={{ root: classes.enterdatabutton }} onClick={() => { openEdit(d); }}>Edit</Button>
                                                        {d.isactive ? <Button variant='contained' size='small' classes={{ root: classes.negativebutton }} onClick={() => { props.performAction(d, "disable"); }}>Disable</Button>
                                                            : <Button variant='contained' size='small' classes={{ root: classes.positivebutton }} onClick={() => { props.performAction(d, "enable"); }}>Enable</Button>}
                                                    </ButtonGroup>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button variant='contained' size='small' classes={{ root: classes.positivebutton }} onClick={() => { props.performAction(d, "resetpassword"); }}>Reset Password</Button>
                                                </Grid>
                                                </Grid>
                                        </TableCell>
                                    </TableRow>
                                    );
                            })
                        }
                    </TableBody>
                        </Table>
                    </Grid>
                    </Grid>
            </Paper>
        </div>
        );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    cellheader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        maxWidth: 90,
        width: 90,
        textAlign: 'center',
        padding: 5,
        '&:last-child': {
            padding: 5
        }
    },
    contentcellheader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        maxWidth: 200,
        width: 200,
        minWidth: 200,
        textAlign: 'center',
        padding: 5,
        '&:last-child': {
            padding: 5
        }
    },
    datecellheader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        maxWidth: 125,
        minWidth: 125,
        width: 125,
        textAlign: 'center',
        padding: 5,
        '&:last-child': {
            padding: 5
        }
    },
    cellborder: {
        borderRight: `1px solid ${theme.palette.divider}`,
        padding: 5,
        '&:last-child': {
            padding: 5
        }
    },
    numbercell: {
        textAlign: 'right',
        fontFamily: 'Courier New'
    },
    datecell: {
        borderRight: `1px solid ${theme.palette.divider}`,
        maxWidth: 125,
        minWidth: 125,
        padding: 5,
        textAlign: 'center'
    },
    contentcell: {
        borderRight: `1px solid ${theme.palette.divider}`,
        maxWidth: 200,
        width: 200,
        minWidth: 200,
        padding: 5,
        textAlign: 'center'
    },
    enterdatabutton: {
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'lightgreen',
            color: 'black'
        },
        color: 'white'
    },
    negativebutton: {
        backgroundColor: 'darkred',
        '&:hover': {
            backgroundColor: 'red',
            color: 'black'
        },
        color: 'white'
    },
    positivebutton: {
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'lightgreen',
            color: 'black'
        },
        color: 'white'
    }
}));