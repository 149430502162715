import * as redux from 'redux';
import * as RegoReducers from './Reducers/RegoReducers';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history'
import * as Thunk from 'redux-thunk'

export const history = createBrowserHistory()

export default function configureRegoOpStore(preloadedState: any): redux.Store<RegoReducers.IState> {
    const composeEnhancer: typeof redux.compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || redux.compose;

    const outstore = redux.createStore(
        RegoReducers.rpReducers(history),
        preloadedState,
        composeEnhancer(
            redux.applyMiddleware(
                routerMiddleware(history),
                Thunk.default
            ),
        ),
    );

    if (module.hot) {
     /*   module.hot.accept('./Reducers/RegoReducer', () => {
            outstore.replaceReducer(RegoReducers.rpReducers(history));
        }); */
    }
    return outstore;
}