import { connect } from 'react-redux'
import * as RegoReducers from '../Business/Reducers/RegoReducers'
import * as HomeBase from '../Components/Home/Base'
import * as HomeBaseReducer from '../Business/Reducers/HomeBaseReducer'
import * as Actions from '../Business/Reducers/Actions'
import * as StoreMiddleware from '../Business/Middleware/StoreManager'
import * as Models from '../Business/Models'


export interface HomeBaseDispatchProps {
    
}

export type HomeBaseProps = HomeBase.HomeBaseStateProps & HomeBaseDispatchProps;

export function mapStateToProps(state: any, componentprops: any): HomeBase.HomeBaseStateProps {
    return { isfetching: state.homebase.isFetching, classes: componentprops.classes };
};

export function mapDispatchToProps(dispatch: any): HomeBaseDispatchProps {
    return {
        clickButton: (id: string) => {
            dispatch(() => { StoreMiddleware.FetchStore(id, dispatch); });
        }
    };
}

export const MappedHomeBasePage = connect<HomeBase.HomeBaseStateProps, HomeBaseDispatchProps, any>(mapStateToProps, mapDispatchToProps)(HomeBase.default)

