import { connect } from 'react-redux'
import * as RegoReducers from '../Business/Reducers/RegoReducers'
import * as StoreHome from '../Components/Store/StoreHome'
import * as StoreReducer from '../Business/Reducers/StoreReducer'
import * as Actions from '../Business/Reducers/Actions'
import * as StoreMiddleware from '../Business/Middleware/StoreManager'
import * as Models from '../Business/Models'


export interface StoreHomeStateProps {
    mode: Actions.StoreMode;
}

export interface StoreHomeDispatchProps {
    clickButton: (id: string) => void;
}

export type StoreHomeProps = StoreHomeStateProps & StoreHomeDispatchProps;

export function mapStateToProps(state: any, componentprops: any): StoreHomeStateProps {
    return { mode: state.store.mode };
};

export function mapDispatchToProps(dispatch: any): StoreHomeDispatchProps {
    return {
        clickButton: (id: string) => {
            dispatch(() => { StoreMiddleware.FetchStore(id, dispatch); });
        }
    };
}

export const MappedStoreHomePage = connect<StoreHomeStateProps, StoreHomeDispatchProps, any>(mapStateToProps, mapDispatchToProps)(StoreHome.StoreHomePage)

