import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import configureRegoOpStore, { history } from './Business/RegoOpStore'
import * as serviceWorker from './serviceWorker';
import './css/RegoOperations.scss'
import { MuiThemeProvider, Theme, createMuiTheme } from '@material-ui/core'
import {red} from '@material-ui/core/colors'

const store = configureRegoOpStore({});
const theme: Theme = createMuiTheme({
    typography: {
    },
    palette: {
        primary: {
            light: '#74779e',
            main: '#525586',
            dark: '#272B68',
            contrastText: '#fff',
        },
        secondary: red,
    },
});

const render = () => {
    ReactDOM.render(
        <AppContainer>
            <MuiThemeProvider theme={theme}>
                <Provider store={store}>
                    <App history={history} />
                </Provider>
            </MuiThemeProvider>
        </AppContainer>
        , document.getElementById('root'))
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

render();

if (module.hot) {
    module.hot.accept('./App', () => {
        /* For Webpack 2.x
           Need to disable babel ES2015 modules transformation in .babelrc
           presets: [
             ["es2015", { "modules": false }]
           ]
        */
        render()

        /* For Webpack 1.x
        const NextApp = require('./App
        ').default
        renderWithHotReload(NextApp)
        */
    })
}
