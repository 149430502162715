import * as redux from 'redux'
import * as actions from './Actions'
import Auth from '@aws-amplify/auth'
import { CognitoUserSession } from 'amazon-cognito-identity-js';


export interface UserState {
    authenticated: boolean;
    isFetching: boolean;
    token: string;
    session: CognitoUserSession | null;
}

export function processState(state: UserState, action: actions.rpAction): UserState {        
    let currstate = state || { authenticated: false, isFetching: false, token: "", session: null };
    
    switch (action.type) {
        case actions.RegoAction.AUTHENTICATED:
            localStorage.setItem("accessToken", action.session.getAccessToken().getJwtToken());
            localStorage.setItem("refreshToken", action.session.getRefreshToken().getToken());
            return Object.assign({}, currstate, { session: action.session, token: action.session.getAccessToken() });
        default:
            return currstate;
    }
}
