// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:706d6ca0-948e-47b2-a2f4-2ffd61e3baac",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_215VOpA8q",
    "aws_user_pools_web_client_id": "78f84ovp3rjn6gr6j0r59tseil"
};


export default awsmobile;
