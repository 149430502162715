import { GUID } from 'aws-sdk/clients/es'


const EmptyGuid: GUID = "00000000-0000-0000-0000-000000000000";

export interface IMenuData {
    value: string;
    text: string;
}

export interface IStoreData {
    id: GUID;
    name: string;
    number: string;
    abbreviation: string;
    franchiseCode: string;
    isActive: boolean;
}

export interface ISalesData {
    weekDateKey: number;
    weekEndDate: string;
    gross: number;
    net: number;
    updatedBy: string;
    lastUpdate: string;
    transactionCount: number;
}

export interface IWeeklySalesJournal {
    ID: GUID;
    storeID: GUID;
    endOfWeekDate: Date;
    weekDateKey: number;
    recordDate: Date;
    addedBy: string;
    grossIncome: number;
    netIncome: number;
    adjustedNet: number;
    transactionCount: number;
    foodCostRate: number;
    laborCostRate: number;
    paperCostRate: number;
    submitted: Date | null;
    transmitted: Date | null;
    reviewed: Date | null;
}

export interface IWeeklySalesInfo {
    storeID: GUID;
    weekDateKey: number;
    lastUpdate: Date;
    updatedBy: GUID;
    addedBy: string;
    isClosed: boolean;
    weekEndDate: string;
    gross: number;
    net: number;
    transactionCount: number;
    latestSalesJournal: IWeeklySalesJournal | null;
    foodCost: number | null;
    laborCost: number | null;
    paperCost: number | null;
    foodCostRate: number | null;
    laborCostRate: number | null;
    paperCostRate: number | null;
    weeklySales: IWeeklySalesSummary | null;
    //SalesWeeklyAccounts: Array<ISalesWeeklyAccount>;
}

export interface IWeeklySalesSummary {
    storeID: GUID;
    weekDateKey: number;
    recordDate: Date;
    reportTypeID: GUID;
    addedBy: string;
    isClosed: boolean;
    endOfWeekDate: string;
    grossIncome: number;
    netIncome: number;
    transactionCount: number;
}

export class Store {
    ID: GUID;
    Name: string;
    Number: string;
    Abbreviation: string;
    Franchise: Franchise | null;
    StreetAddress: string;
    StreetAddress2: string;
    City: string;
    State: string;
    Zip: string;
    Country: string;
    PhoneNumbers: Array<PhoneNumber>;
    label: string;

    constructor() {
        this.ID = EmptyGuid;
        this.PhoneNumbers = new Array<PhoneNumber>();
        this.Name = "";
        this.Number = "";
        this.Abbreviation = "";
        this.StreetAddress = "";
        this.StreetAddress2 = "";
        this.City = "";
        this.State = "";
        this.Zip = "";
        this.Country = "";
        this.Franchise = null;
        this.label = "";
    }

    Hydrate(data: any) {
        this.ID = data.id;
        this.Name = data.name;
        this.Number = data.number;
        this.Abbreviation = data.abbreviation; 
        this.label = data.number;
    }

    setFranchise(franchise: Franchise) {
        this.Franchise = franchise;
        this.label = franchise.Code + " - " + this.Number;
    }
}

export class PhoneNumber {
    ID: GUID;
    NumberType: string;
    Number: string;

    constructor() {
        this.ID = EmptyGuid;
        this.NumberType = "Main";
        this.Number = "";
    }
}

export interface IFranchiseSummary {
    id: GUID;
    code: string;
    name: string;
}

export class Franchise{
    ID: GUID;
    Code: string;
    Name: string;

    constructor() {
        this.ID = EmptyGuid;
        this.Code = "N/A";
        this.Name = "Undefined";
    }

    Hydrate(data: any) {
        this.ID = data.id;
        this.Name = data.name;
        this.Code = data.code;
    }
}

export class SalesDataForWeek {
    journal: WeeklySalesJournal | null;

    constructor() {
        this.journal = null;
    }
}

export class WeeklySalesJournal {
    ID: GUID = EmptyGuid;
    StoreID: GUID = EmptyGuid;
    EndOfWeekDate: Date = new Date('1/1/1901');
    WeekDateKey: number = 0;
    RecordDate: Date = new Date('1/1/1901');
    AddedBy: string = "";
    GrossIncome: number = 0.00;
    NetIncome: number =  0.00;
    AdjustedNet: number = 0.00;
    TransactionCount: number = 0;
    FoodCostRate: number = 0.00;
    LaborCostRate: number = 0.00;
    PaperCostRate: number = 0.00;
    Submitted: Date | null = null;
    Transmitted: Date | null = null;
    Reviewed: Date | null = null;

    constructor() {
    }

    Hydrate(data: any): void {
        if(data.id) this.ID = data.id;
        this.StoreID = data.storeid;
        this.EndOfWeekDate = data.endofweekdate;
        this.WeekDateKey = data.weekdatekey;
        this.RecordDate = data.recorddate;
        this.AddedBy = data.addedby;
        this.GrossIncome = data.gross;
        this.NetIncome = data.net;
        this.AdjustedNet = data.adjustednet;
        this.TransactionCount = data.transactioncount;
        this.FoodCostRate = data.foodcostrate == null || data.papercostrate == "" ? 0.00 : data.foodcostrate >= 0.25 ? data.foodcostrate / 100.00 : data.foodcostrate;
        this.LaborCostRate = data.laborcostrate == null || data.papercostrate == "" ? 0.00 : data.laborcostrate >= 0.25 ? data.laborcostrate / 100.00 : data.laborcostrate;
        this.PaperCostRate = data.papercostrate == null || data.papercostrate == "" ? 0.00 : data.papercostrate >= 0.25 ? data.papercostrate / 100.00 : data.papercostrate;
        if(data.submitted) this.Submitted = data.submitted;
        if(data.transmitted) this.Transmitted = data.transmitted;
        if(data.reviewed) this.Reviewed = data.reviewed;
    }
}

export interface IUserInfo {
    username: string;
    userid: string;
    isactive: boolean;
    roles: Array<UserRole>;
}

export class UserInfo implements IUserInfo {
    username: string;
    userid: string;
    isactive: boolean;
    roles: Array<UserRole>;

    constructor() {
        this.username = "";
        this.userid = "";
        this.isactive = false;
        this.roles = [];
    }

    Hydrate(data: any): void {
        this.userid = data.userID;
        this.username = data.username;
        this.isactive = data.isActive;
        data.storeRoles.forEach((v: any, i: any) => {
            this.roles.push({
                id: v.id,
                name: v.roletype,
                contexttype: "Store",
                contextname: v.storeName,
                contextid: v.storeID
            });
        });
        data.franchiseRoles.forEach((v: any, i: any) => {
            this.roles.push({
                id: v.id,
                name: v.roleType,
                contexttype: "Franchise",
                contextname: v.franchiseName,
                contextid: v.franchiseID
            });
        });
    }
}

export interface UserRole {
    id: string; // GUID of user role
    name: string; // Name of role (Admin, Franchisee)
    contextname: string; // Name of franchise or store role applies to
    contexttype: string; // Type of context (franchise, store, admin (for blanket rego roles))
    contextid: string;
}

export interface FormError {
    errorstate: boolean;
    errormsg: string;
}


