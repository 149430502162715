import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
//var Authenticator = require('aws-amplify-react').Authenticator;
import Auth from '@aws-amplify/auth'
var withAuthenticator = require('aws-amplify-react').withAuthenticator;
//import { withAuthenticator } from 'aws-amplify-react';
import { ConnectedRouter } from 'connected-react-router'
import { Link } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import * as StoreHome from './Mappings/StoreHomeMapping'
import * as StoreSelector from './Mappings/StoreSelectorMapping'
import * as HomeBase from './Mappings/HomeBaseMapping'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Drawer, List, ListItem, Theme, CssBaseline, ListItemIcon, ListItemText, Icon, Button } from '@material-ui/core'
import { UserHome } from './Components/Users/UserHome';

import { createStyles, WithStyles, withStyles } from '@material-ui/core'
import classNames from 'classnames'

Amplify.configure(aws_exports);

const drawerWidth = 100;

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerActive: {
        backgroundColor: theme.palette.primary.dark,
        color: '#fff',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
});

interface IAppProps extends WithStyles<typeof styles> {
    history: any;
}

interface IAppState {
    mode: string;
}

const App = withStyles(styles)(
    class extends Component<IAppProps, any> {
        constructor(props: any) {
            super(props);
            let currmode: string = "";
            switch (this.props.history.location.pathname) {
                case "/stores":
                    currmode = 'store';
                    break;
                case "/users":
                    currmode = 'users';
                    break;
                default:
                    currmode = 'home';
                    break;
            }
            this.state = { mode: currmode };
        }

        componentWillMount(): void {
            Auth.currentSession().then((u: CognitoUserSession) => {
                localStorage.setItem("identityToken", u.getAccessToken().getJwtToken());
            });
        }

        navigate(path: string, mode: string): void {
            this.setState({ mode: mode });
            this.props.history.push(path);
        }

        render() {
            const { classes } = this.props;

            return (
                <ConnectedRouter history={this.props.history}>
                    <div className="fullframe">
                        <CssBaseline />
                        <div className="navmenu">
                            <Button variant='contained' color='primary' className="navbutton" classes={this.state.mode == 'home' ? { root: classes.drawerActive } : {}} onClick={() => { this.navigate('/', 'home'); }}>
                                <div>
                                    <Icon>home</Icon>
                                    <div>Home</div>
                                </div>
                            </Button>
                            <Button variant='contained' color='primary' className="navbutton" classes={this.state.mode == 'store' ? { root: classes.drawerActive } : {}} onClick={() => { this.navigate('/stores', 'store'); }}>
                                <div>
                                    <Icon>store</Icon>
                                    <div>Stores</div>
                                </div>
                            </Button>
                            <Button variant='contained' color='primary' className="navbutton" classes={this.state.mode == 'users' ? { root: classes.drawerActive } : {}} onClick={() => { this.navigate('/users', 'users'); }}>
                                <div>
                                    <Icon>people</Icon>
                                    <div>Users</div>
                                </div>
                            </Button>
                        </div>
                            <div className='contentframe'>
                                <div>
                                    <Switch>
                                        <Route exact path="/stores/" component={StoreSelector.MappedStoreSelectorControl} />)} />
                                    </Switch>
                                </div>
                                <div>
                                    <Switch>
                                        <Route exact path="/" component={HomeBase.MappedHomeBasePage} />
                                        <Route path="/stores/" component={StoreHome.MappedStoreHomePage} />
                                        <Route path="/users/" component={UserHome} />
                                        <Route render={() => (<div>Route Not Found</div>)} />
                                    </Switch>
                                </div>
                            </div>
                    </div>
                </ConnectedRouter>
            );
        }
    });

export default withAuthenticator(App, true)
