import * as UIReducer from "./UIReducer"
import * as UserReducer from "./UserReducer"
import * as actions from "./Actions"
import * as redux from 'redux'
import { History } from 'history'
import { RouterState, connectRouter } from 'connected-react-router'
import * as StoreReducer from "./StoreReducer"
import * as StoreSelectorReducer from "./StoreSelectorReducer"
import * as StoreSalesReducer from "./StoreSalesReducer"
import * as HomeBaseReducer from "./HomeBaseReducer"


export interface IState {
    ui: UIReducer.UIState,
    user: UserReducer.UserState,
    store: StoreReducer.StoreState,
    storeselector: StoreSelectorReducer.StoreSelectorState,
    storesales: StoreSalesReducer.StoreSalesState,
    homebase: HomeBaseReducer.HomeBaseState,
    router: RouterState
};

export const rpReducers = (history: History) => redux.combineReducers<IState>({
    ui: UIReducer.processState,
    user: UserReducer.processState,
    store: StoreReducer.processState,
    storeselector: StoreSelectorReducer.processState,
    storesales: StoreSalesReducer.processState,
    homebase: HomeBaseReducer.processState,
    router: connectRouter(history)
} as any);
