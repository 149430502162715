import * as Models from './Models'

export interface FormError {
    errorstate: boolean;
    errormsg: string;
}

export interface WeeklySalesFormState {
    storeid: string;
    selectedweek: Models.IWeeklySalesInfo | null;
    weekdatekey: number;
    endofweekdate: Date | null;
    gross: string;
    net: string;
    adjustednet: string;
    transactioncount: string;
    foodcostrate: string;
    laborcostrate: string;
    papercostrate: string;
    errors: { [id: string]: FormError };
    requireconfirmation: boolean;
    confirmed: boolean;
}

export function PopulateWeeklySalesFormState(storeid: string, data: Models.IWeeklySalesInfo): WeeklySalesFormState {
    let outobj: WeeklySalesFormState;
    let usejournal = data.latestSalesJournal != null && (data.lastUpdate == null || data.lastUpdate < data.latestSalesJournal.recordDate);
    if (usejournal && data.latestSalesJournal != null) {
        outobj = {
            storeid: storeid,
            selectedweek: data,
            weekdatekey: data.weekDateKey,
            endofweekdate: new Date(data.weekEndDate),
            gross: data.latestSalesJournal.grossIncome.toString(),
            net: data.latestSalesJournal.netIncome.toString(),
            adjustednet: "",
            transactioncount: data.latestSalesJournal.transactionCount.toString(),
            foodcostrate: data.foodCostRate != null ? (data.foodCostRate * 100).toFixed(4) : "",
            laborcostrate: data.laborCostRate != null ? (data.laborCostRate * 100).toFixed(4) : "",
            papercostrate: data.paperCostRate != null ? (data.paperCostRate * 100).toFixed(4) : "",
            errors: {
                "gross": { errorstate: false, errormsg: "" },
                "net": { errorstate: false, errormsg: "" },
                "adjustednet": { errorstate: false, errormsg: "" },
                "transactioncount": { errorstate: false, errormsg: "" },
                "foodcostrate": { errorstate: false, errormsg: "" },
                "laborcostrate": { errorstate: false, errormsg: "" },
                "papercostrate": { errorstate: false, errormsg: "" },
            },
            requireconfirmation: false,
            confirmed: false
        };
        return outobj;
    }
    // Change math in below to convert actual costs to cost rates
    outobj = {
        storeid: storeid,
        selectedweek: data,
        weekdatekey: data.weekDateKey,
        endofweekdate: new Date(data.weekEndDate),
        gross: data.gross == null || data.gross == 0 ? "" : data.gross.toString(),
        net: data.net == null || data.net == 0 ? "" : data.net.toString(),
        adjustednet: "",
        transactioncount: data.transactionCount == null || data.transactionCount == 0 ? "" : data.transactionCount.toString(),
        foodcostrate: data.foodCostRate != null ? (data.foodCostRate * 100).toFixed(4) : "",
        laborcostrate: data.laborCostRate != null ? (data.laborCostRate * 100).toFixed(4) : "",
        papercostrate: data.paperCostRate != null ? (data.paperCostRate * 100).toFixed(4) : "",
        errors: {
            "gross": { errorstate: false, errormsg: "" },
            "net": { errorstate: false, errormsg: "" },
            "adjustednet": { errorstate: false, errormsg: "" },
            "transactioncount": { errorstate: false, errormsg: "" },
            "foodcostrate": { errorstate: false, errormsg: "" },
            "laborcostrate": { errorstate: false, errormsg: "" },
            "papercostrate": { errorstate: false, errormsg: "" },
        },
        requireconfirmation: false,
        confirmed: false
    };

    return outobj;
}