import React, { useEffect, useState, useReducer } from 'react';
import { Tooltip, FormControl, List, ListItem, ButtonGroup, ListItemText, ListItemSecondaryAction, Paper, Chip, InputLabel, Grid, FormHelperText, Select, MenuItem, OutlinedInput, FormControlLabel, DialogActions, Button, makeStyles, Theme, createStyles, Checkbox } from '@material-ui/core';
import { IUserInfo, FormError, IFranchiseSummary, IStoreData, UserRole } from '../../Business/Models';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

export interface ISelectStoresProps {
    stores: Array<IStoreData>;
    selectedStores: Array<string>;
    toggleStore: (id: string) => void;
}

export interface ISelectStoreSearchState {
    filter: string;
    filteredStores: Array<IStoreData>;
}

const GetDefaultSearchState: () => ISelectStoreSearchState = () => {
    return {
        filter: "",
        filteredStores: []
    };
}

/*
 * Needs:
 * 1) List of selected store codes
 * 2) List of possible stores
 * 3) Search filter
 * 4) State that updates the list of matches based upon the filter (reducer based?)
*/


export const SelectStores: React.FC<ISelectStoresProps> = (props, context) => {
    const classes = useStyles();
    const [searchState, setSearchState] = useState<ISelectStoreSearchState>(GetDefaultSearchState());

    const runFilter: () => void = () => {
        if (searchState.filter == "" || searchState.filter == null) {
            setSearchState({ ...searchState, filter: "", filteredStores: [] });
            return;
        }
        let filteredStores: Array<IStoreData> = props.stores.filter(s => (s.number.includes(searchState.filter) || s.name.includes(searchState.filter)) && (props.selectedStores.indexOf(s.id) == -1));
        setSearchState({ ...searchState, filteredStores: filteredStores });
    }

    const toggleStore: (storeid: string) => void = (storeid) => {
        props.toggleStore(storeid);
    }

    useEffect(() => {
        runFilter();
    }, [props.selectedStores]);

    return (
            <Grid container justify='space-between' alignItems='center' alignContent='center'>
                <Grid item xs={6}>
                    <Tooltip title="Filter stores by name or number">
                        <FormControl margin='dense' variant='outlined'>
                            <InputLabel htmlFor="store-filter">Search Stores</InputLabel>
                            <OutlinedInput labelWidth={80} id="store-filter" value={searchState.filter} onChange={(e) => setSearchState({ ...searchState, filter: e.target.value })} />
                        </FormControl>
                    </Tooltip>
                </Grid>
            <Grid item xs={4}>
                    <ButtonGroup>
                    <Button onClick={() => runFilter()} variant='contained' color='primary'><SearchIcon /></Button>
                    <Button onClick={() => setSearchState({ ...searchState, filter: "", filteredStores: [] })} variant='contained' color='primary'><ClearIcon /></Button>
                    </ButtonGroup>
                </Grid>
                {searchState.filter != "" ?
                    <Grid item xs={12}>
                            <List dense>
                        {searchState.filteredStores.map((s, i) => {
                            return (
                                <ListItem key={s.id} button onClick={() => { toggleStore(s.id); }}>
                                    <ListItemText primary={`${s.name} (${s.number})`} />
                                </ListItem>
                            );
                        })}
                            </List>
                    </Grid> : null}
                <Grid item xs={12}>
                    <Paper>
                        {props.selectedStores.map((s, i) => {

                            let s0 = props.stores.find(st => st.id == s);
                            if (s0 != null) {
                                let id: string = s0.id;
                                return (
                                    <Chip
                                        key={id}
                                        label={s0.number}
                                        onDelete={() => { toggleStore(id); }}
                                        className={classes.chip}
                                    />
                                );
                            }
                            return null;
                        })}
                    </Paper>
                </Grid>
            </Grid>
    );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    tooltipstyle: {
        fontSize: 'medium',
        backgroundColor: 'black'
    },
    warningstyle: {
        backgroundColor: 'red',
        fontWeight: 'bold'
    },
    chip: {
        background: "#ddd",
        margin: theme.spacing(0.5)
    }
}));
