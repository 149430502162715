import * as Models from '../Models'
import { GUID } from 'aws-sdk/clients/es'
import Auth from '@aws-amplify/auth'



export class Repository {
    apiUrl: string = "https://api.regorestaurants.com/api/";
    token: string;
    constructor(cognitoidentitytoken: string) {
        this.token = cognitoidentitytoken;
    }

    FetchStore(id: GUID, callback: (result: Models.Store | null, roles: Array<string> | null) => void): void {
        let urlsuffix: string = "stores/lookup/" + id;

        fetch(this.apiUrl + urlsuffix, {
            method: 'GET',
            headers: {
                "idtoken": this.token
            }
        }).then(
            (storedata) => {
                storedata.json().then((resp) => {
                    if (resp === undefined) {
                        callback(null, null);
                        return;
                    }
                    let outstore: Models.Store = new Models.Store();
                    outstore.Hydrate(resp.store);
                    let franchise: Models.Franchise = new Models.Franchise();
                    franchise.Hydrate(resp.store.franchise);
                    outstore.setFranchise(franchise);
                    callback(outstore, resp.roles);
                });
            }
        );
    }

    FetchStores(callback: (result: Array<Models.Store>) => void): void {
        let urlsuffix: string = "stores";

        fetch(this.apiUrl + urlsuffix, {
            method: 'GET',
            headers: {
                "idtoken": this.token
            }
        }).then(
            (storedata) => {
                storedata.json().then((resp) => {
                    let outlist: Array<Models.Store> = new Array<Models.Store>();
                    resp.forEach((st: any) => {
                        let store: Models.Store = new Models.Store();
                        store.Hydrate(st);
                        outlist.push(store);
                    });
                    outlist.sort((lside: Models.Store, rside: Models.Store): number => {
                        if (lside.Number < rside.Number) return -1;
                        if (lside.Number > rside.Number) return 1;
                        return 0;
                    });
                    callback(outlist);
                });
            });
    }

    FetchStoreSummaries(callback: (result: Array<Models.IStoreData>) => void): void {
        let urlsuffix: string = "stores/summaries";
        fetch(this.apiUrl + urlsuffix, {
            method: 'GET',
            headers: {
                "idtoken": this.token
            }
        }).then(
            (summarydata) => {
                summarydata.json().then((resp) => {
                    let outlist: Array<Models.IStoreData> = resp;
                    outlist.sort((lside: Models.IStoreData, rside: Models.IStoreData): number => {
                        if (lside.isActive < rside.isActive) return -1;
                        if (lside.franchiseCode < rside.franchiseCode) return -1;
                        if (lside.franchiseCode > rside.franchiseCode) return 1;
                        if (lside.number < rside.number) return -1;
                        if (lside.number > rside.number) return 1;
                        return 0;
                    });
                    callback(outlist);
                });
            }
        );
    }

    FetchFranchiseSummaries(callback: (result: Array<Models.IFranchiseSummary>) => void): void {
        let urlsuffix: string = "franchises/summaries";
        fetch(this.apiUrl + urlsuffix, {
            method: 'GET',
            headers: {
                "idtoken": this.token
            }
        }).then(
            (summarydata) => {
                summarydata.json().then((resp) => {
                    let outlist: Array<Models.IFranchiseSummary> = resp;
                    outlist.sort((lside: Models.IFranchiseSummary, rside: Models.IFranchiseSummary): number => {
                        if (lside.name < rside.name) return -1;
                        if (lside.name > rside.name) return 1;
                        if (lside.code < rside.code) return -1;
                        if (lside.code > rside.code) return 1;
                        return 0;
                    });
                    callback(outlist);
                });
            }
        );
    }

    private FetchMenuData(url: string, callback: (result: Array<any>) => void, sortfunc?: (l: Models.IMenuData, r: Models.IMenuData) => number) {
        fetch(url, {
            method: 'GET',
            headers: {
                "idtoken": this.token
            }
        }).then((menuitems) => {
            menuitems.json().then((resp) => {
                let outlist: Array<Models.IMenuData> = new Array<Models.IMenuData>();
                resp.forEach((st: any) => {
                    let store: Models.IMenuData = {
                        value: st.value,
                        text: st.label
                    };
                    outlist.push(store);
                });
                if (sortfunc != null) {
                    outlist.sort(sortfunc);
                }
                else {
                    outlist.sort((lside: Models.IMenuData, rside: Models.IMenuData): number => {
                        if (lside.text < rside.text)
                            return -1;
                        if (lside.text > rside.text)
                            return 1;
                        return 0;
                    });
                }
                callback(outlist);
            });
        });
    }

    FetchStoreOptions(callback: (result: Array<any>) => void): void {
        let urlsuffix: string = "stores/options";
        let url: string = this.apiUrl + urlsuffix;
        this.FetchMenuData(url, callback);
    }

    FetchWeekEndOptions(storeid: string, callback: (result: Array<any>) => void): void {
        let urlsuffix: string = "weeklysales/" + storeid + "/weeklist";
        let url: string = this.apiUrl + urlsuffix;
        this.FetchMenuData(url, callback, (lside: Models.IMenuData, rside: Models.IMenuData): number => {
            if (rside.value < lside.value)
                return -1;
            if (rside.value > lside.value)
                return 1;
            return 0;
        });
    }

    FetchStoreWeeklySales(storeid: string, callback: (s: Array<Models.IWeeklySalesInfo>) => void): void {
        let urlsuffix: string = "weeklysales/" + storeid + "/saleslist";
        let url: string = this.apiUrl + urlsuffix;
        fetch(url, {
            method: 'GET',
            headers: {
                "idtoken": this.token
            }
        }).then((salesdata) => {
            salesdata.json().then((dt) => {
                if (dt.length == 0) {
                    callback([]);
                    return;
                }
                callback(dt);
                return;
            });
        });
    }

    FetchSalesForWeek(storeid: string, weekdatekey: number, callback: (result: any) => void): void {
        let urlsuffix: string = "weeklysales/" + storeid + "/" + weekdatekey.toString();
        let url: string = this.apiUrl + urlsuffix;
        fetch(url, {
            method: 'GET',
            headers: {
                "idtoken": this.token
            }
        }).then(
            (salesdata) => {
                salesdata.json().then((resp) => {
                    if (resp.length == 0) {
                        callback(null);
                        return;
                    }
                    let outstore: Models.Store = new Models.Store();
                    outstore.Hydrate(resp);
                    let franchise: Models.Franchise = new Models.Franchise();
                    franchise.Hydrate(resp.franchise);
                    outstore.setFranchise(franchise);
                    callback(outstore);
                });
            }
        );
    }

    RunUserAction(userid: string, action: string, callback: (result: any) => void): void {
        let urlsuffix: string = "users/" + userid + "/" + action;
        let url: string = this.apiUrl + urlsuffix;
        fetch(url, {
            method: 'GET',
            headers: {
                "idtoken": this.token
            }
        }).then(
            (data) => {
                data.json().then((resp) => {
                    callback(data);
                });
            }
        )
    }

    PostWeeklySalesJournal(data: Models.WeeklySalesJournal, callback: () => void): void {
        let urlsuffix: string = "weeklysales/";
        let url: string = this.apiUrl + urlsuffix;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "idtoken": this.token
            }
        }).then((response) => {
            if (response.status == 200) {
                callback();
            }
            else {
                alert("Update failed!");
            }
            //   return response.json();
        });//.then((j) => {
            //  if (j.length == 0) {
            //      callback(null);
            //      return;
            //  }

        //});
    }

    PostUserInfo(data: Models.IUserInfo, callback: () => void): void {
        let urlsuffix: string = "users";
        let url: string = this.apiUrl + urlsuffix;
        let body: any = {
            userid: data.userid,
            username: data.username,
            isactive: data.isactive,
            isadministrator: data.roles.some(r => r.name == "Administrator"),
            storeroles: data.roles.filter(r => r.contexttype == "Store").map((sr) => {
                return {
                    id: "00000000-0000-0000-0000-000000000000",
                    roletype: sr.name,
                    storeid: sr.contextid,
                    storename: sr.contextname
                };
            }),
            franchiseroles: data.roles.filter(r => r.contexttype == "Franchise").map((fr) => {
                return {
                    id: "00000000-0000-0000-0000-000000000000",
                    roletype: fr.name,
                    franchiseid: fr.contextid,
                    franchisename: fr.contextname,
                    franchisecode: ""
                };
            })
        };
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "idtoken": this.token
            }
        }).then((response) => {
            if (response.status == 200) {
                callback();
            }
            else {
                alert("Update failed!");
            }
            //   return response.json();
        });
    }
       
    FetchUsers(callback: (result: Array<Models.UserInfo>) => void): void {
        let urlsuffix: string = "users";
        let url: string = this.apiUrl + urlsuffix;
        fetch(url, {
            method: 'GET',
            headers: {
                "idtoken": this.token
            }
        }).then(
            (usersdata) => {
                usersdata.json().then((resp) => {
                    let outlist: Array<Models.UserInfo> = new Array<Models.UserInfo>();
                    resp.forEach((usr: any) => {
                        let user: Models.UserInfo = new Models.UserInfo();
                        user.Hydrate(usr);
                        outlist.push(user);
                    });
                    outlist.sort((lside: Models.UserInfo, rside: Models.UserInfo): number => {
                        if (lside.username < rside.username) return -1;
                        if (lside.username > rside.username) return 1;
                        return 0;
                    });
                    callback(outlist);
                });
            }
        );
    }
}
 
