import * as redux from 'redux'
import * as actions from './Actions'
import * as Models from '../Models'
import * as ApiRepository from '../Repositories/ApiRepository'

export interface HomeBaseState {
    isFetching: boolean;
}

export function processState(state: HomeBaseState, action: actions.rpAction): HomeBaseState {
    let outstate = state || { isFetching: false };
    if (action == null) {
        return outstate;
    }

    switch (action.type) {
        default:
            return outstate;
    }
}